<template>
  <Sidebar ref="sidebar">
    <template #header>
      <div class="h-topbar flex w-max flex-row items-center gap-0.5 overflow-hidden">
        <LayoutNavigationWorkspace />
        <div
          class="hidden shrink-0 group-hover/sidebar:block group-hover/sidebar:delay-200 group-data-[open=true]/sidebar:block group-data-[open=true]/sidebar:delay-200"
        >
          <Tooltip :text="t('lock_navigation')">
            <ClientOnly>
              <Button :variant="ButtonVariant.Tertiary" icon-only :active="locked" @click="lock">
                <Icon src="bold/Sidebar" />
              </Button>
            </ClientOnly>
          </Tooltip>
        </div>
      </div>
    </template>
    <div class="flex flex-col">
      <template v-for="(entryGroup, index) in visibleEntries" :key="index">
        <div class="flex flex-col gap-1 pt-2 first:pb-2">
          <LayoutNavigationItem v-for="entry in entryGroup" :key="entry.route_key" :entry="entry" />
        </div>
      </template>
    </div>
    <template #footer>
      <div class="mt-auto flex flex-col gap-0.5">
        <div class="flex flex-col gap-1">
          <LayoutNavigationItem v-for="(action, index) in actions" :key="index" :entry="action" />
        </div>
        <LayoutNavigationUser />
      </div>
    </template>
  </Sidebar>
</template>

<script setup lang="ts">
  import { ButtonVariant } from "~/types/global";
  import { RouteName } from "~/types/routes";
  import { computed, ref } from "vue";
  import { useI18n } from "vue-i18n";

  defineOptions({
    name: "LayoutNavigation",
  });

  const { t } = useI18n();

  const sidebar = ref<HTMLElement>();
  const lock = () => sidebar.value?.lock();
  const locked = computed(() => sidebar.value?.locked);
  const { hasCapability } = useCapabilities();
  const openSearch = () => {
    if (!import.meta.client) return;
    window?.$searchModal?.open();
  };

  const entries = computed(() => [
    [
      {
        label: t("search"),
        icon: "regular/MagnifyingGlass",
        action: openSearch,
      },
      {
        label: t("workspace_settings"),
        icon: "regular/gear",
        action: import.meta.client ? $workspace_settings.open : undefined,
        hidden: !hasCapability("account.*"),
      },
    ],
    [
      {
        label: t("sidebar.shipments"),
        route: "/shipments",
        icon: "custom/regular/forsendelse",
        route_key: RouteName.shipments,
        hidden: !useHasPageAccess(RouteName.shipments),
      },
      {
        label: t("sidebar.clickcollect"),
        route: "/clickcollect",
        icon: "regular/MapPin",
        route_key: RouteName.click_collect,
        hidden: true, //!useHasPageAccess(RouteName.click_collect),
      },
      {
        label: t("sidebar.returns"),
        route: "/returns",
        icon: "custom/regular/returnering",
        route_key: RouteName.returns,
        hidden: !useHasPageAccess(RouteName.returns),
      },
      {
        label: t("sidebar.tickets"),
        route: "/tickets",
        icon: "regular/ClipboardText",
        route_key: RouteName.tickets,
        hidden: !useHasPageAccess(RouteName.tickets),
      },
      /*{
       label: t("sidebar.warehouse_management"),
       route: "/warehouse",
       icon: "regular/warehouse",
       route_key: "warehouse_management",
     },*/
      {
        label: t("sidebar.pickup"),
        route: "/sortings",
        icon: "regular/ArrowsLeftRight",
        route_key: RouteName.sortings,
        hidden: !useHasPageAccess(RouteName.sortings),
      },
      /*{
       label: t("sidebar.insight"),
       route: "/insight",
       icon: "regular/chart_donut",
       route_key: "insight",
     },*/
    ],
    [
      {
        label: t("purchase_order", 99),
        icon: "regular/package",
        route: "/purchaseorders",
        route_key: "purchaseorders",
        hidden: !useHasPageAccess(RouteName.purchase_orders),
      },
      {
        label: t("sea_freight"),
        icon: "regular/boat",
        route: "/seafreight",
        route_key: "seafreight",
        hidden: !useHasPageAccess(RouteName.seafreight),
      },
      {
        label: t("container", 99),
        icon: "regular/shipping_container",
        route: "/containers",
        route_key: "containers",
        hidden: !useHasPageAccess(RouteName.containers),
      },
    ],
  ]);

  const visibleEntries = computed(() => entries.value.map((entryGroup) => entryGroup.filter((entry) => !entry.hidden)));

  const actions: (ISidebarAction | ISidebarEntry)[] = [
    /*{
     label: t("sidebar.app_center"),
     icon: "regular/circles_three_plus",
     action: () => {
       $toast.add({
         title: t("not_yet_available"),
       });
     },
     key: "app_center",
   },
  /* {
     label: t("sidebar.notifications"),
     icon: "regular/Tray",
     action: () => {
       $toast.add({
         title: t("not_yet_available"),
       });
     },
     key: "notifications",
   },*/
    {
      label: t("sidebar.open_help_center"),
      icon: "regular/Lifebuoy",
      route_key: "help",
      route: "https://help.homerunner.com",
      target: "_blank",
      key: "help",
    },
  ];
</script>
